import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'childCheckbox']

  toggle () {
    const checkboxState = this.checkboxTarget.checked
    this.childCheckboxTargets.forEach(checkbox => {
      checkbox.value = checkboxState
    })
  }
}
